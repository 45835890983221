import { GetServerSideProps } from 'next';
import Head from 'next/head';
import {
  HomePageEventsDocument,
  HomePageEventsQuery,
  HomePageEventsQueryVariables,
} from '@/apollo/operations';
import { HomePageEvents as DefEvents } from '@/components/events';
import { initializeApollo, addApolloState } from '@/utils/apollo-client';
import { styled } from '@mui/material/styles';

const HomePage = () => (
  <>
    <Head>
      <title>Stake3 - Poker Staking - Back the best, win the most</title>
      <meta
        name="description"
        content="Stake3 - Poker Staking - Back the best, win the most"
      />
    </Head>
    <Events />
  </>
);

const Events = styled(DefEvents)`
  margin-top: 50px;
`;

export const getServerSideProps: GetServerSideProps = async () => {
  const apolloClient = await initializeApollo();

  await apolloClient.query<HomePageEventsQuery, HomePageEventsQueryVariables>({
    query: HomePageEventsDocument,
  });

  return addApolloState(apolloClient, {
    props: {},
  });
};

export default HomePage;
